<template>
  <md-card class="md-layout-item md-size-100 md-small-size-100">
    <div class="md-layout" v-if="this.email">
      <div class="md-layout-item md-small-size-100 md-size-100">
        <div class="md-layout md-size-60 md-alignment-center header">
          <p class="md-headline center">Verification required</p>
          <p class="sub-headline center">Check your inbox and follow the link in the email</p>
          <p class="sub-headline center">verify your account for:</p>
          <p class="sub-email center">{{ this.email }}</p>
          <p class="center">
            <img class="img" src="/images/email-verification.png" alt="Confirm Account">
          </p>
        </div>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-100" style="margin-bottom: 20px;">
        <div class="md-layout md-alignment-center-center md-size-100">
          DIDN'T GET AN EMAIL? <a><span class="resend" @click="resendEmail"> RESEND </span></a>
        </div>
      </div>
    </div>

    <md-progress-bar class="md-accent" md-mode="indeterminate" v-if="isLoading"/>

  </md-card>
</template>

<script>
import loaderMixin from '@/mixins/loaderMixin';
import { CHECK_CONFIRM_ACCOUNT, RESEND_CONFIRM_EMAIL } from '@/store/modules/auth/authActions.type';

export default {
  name: 'ConfirmAccountPage',
  mixins: [
    loaderMixin,
  ],
  data: () => ({
    email: null,
    publicPath: process.env.BASE_URL,
  }),
  mounted() {
    this.confirmAction();
  },
  methods: {
    resendEmail() {
      const payload = {
        email: this.email,
      };
      this.$store.dispatch(RESEND_CONFIRM_EMAIL, payload)
        .then((response) => {
          this.$toasted.show(response.message, {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error(CHECK_CONFIRM_ACCOUNT, error);
          this.$toasted.show(error.message, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          if (error.status === 'validation_error' && error.errors) {
            this.errors = error.errors;
          }
        });
    },
    confirmAction() {
      const payload = {
        email: this.$route.params.email,
      };
      this.$store.dispatch(CHECK_CONFIRM_ACCOUNT, payload)
        .then((response) => {
          if (response.data.isConfirmed) {
            this.$router.push('/');
          } else {
            this.email = response.data.email;
          }
        })
        .catch((error) => {
          this.$log.error(CHECK_CONFIRM_ACCOUNT, error);
          this.$toasted.show(error.message, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          if (error.status === 'validation_error' && error.errors) {
            this.errors = error.errors;
          }
          this.$router.push('/');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: grid;
  align-items: center;
  justify-content: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.md-headline {
  font-size: 23px;
  margin-bottom: 35px;
}

.sub-headline {
  font-size: 20px;
  margin: 0;
}

.sub-email {
  font-size: 22px;
  font-weight: 600;
  color: #000;
  margin-top: 35px;
  margin-bottom: 0;
}

.img {
  width: 75px;
  height: 75px;
  margin-bottom: 20px;
}

.resend {
  margin-left: 5px;
  color: rgb(80, 131, 207);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

@media only screen and (min-width: 301px) and (max-width: 540px) {
  .resend {
    font-size: 15px;
  }

  .md-headline {
    font-size: 18px;
  }

  .sub-headline {
    font-size: 15px;
  }

  .sub-email {
    font-size: 16px;
  }
}

@media only screen and (max-width: 300px) {
  .resend {
    font-size: 10px;
  }

  .md-headline {
    font-size: 15px;
  }

  .sub-headline {
    font-size: 11px;
  }

  .sub-email {
    font-size: 10px;
  }
}
</style>
